/* define variables */
/* 1rem = 16px */

:root {
  --glowva-primary: #00A264;
  --glowva-primary-dark: #343434;
  --glowva-secondary-white: #FCFCFC;
  --glowva-secondary-dark-gray: #818181;
  --glowva-secondary-light-gray: #E0E0E0;
  --glowva-tertiary-green: #076E46;
  --glowva-tertiary-dark-green: #00794C;
  --glowva-tertiary-red: #FF453A;
  --glowva-feature-gray: #BFBFBF;
  --glowva-light-gray: #B1B9BE;
  --glowva-light-green: #4CBD92;
}

/* Large screens (>= 992px) */
@media (min-width: 992px) {
  :root {
    --glowva-font-size-h1: 2rem;
    --glowva-font-size-h2: 1.75rem;
    --glowva-font-size-h3: 1.5rem;
    --glowva-font-size-h4: 1.375rem;
    --glowva-font-size-h5: 1.25rem;
    --glowva-font-size-h6: 1.125rem;
    --glowva-font-size-p: 1rem;
    --glowva-font-size-s: 0.875rem;
    --glowva-font-size-xs: 0.8125rem;
    --glowva-font-size-intro-title: 4rem;
    /* 64px */
    --glowva-font-size-intro-subtitle: 2.5rem;
    /* 40px */
    --glowva-font-size-intro-p: 1.75rem;
    /* 28px */
    --glowva-font-size-intro-learn-more: var(--glowva-font-size-h5);
    /* 20px */
    --glowva-font-size-48px: 2.6rem;
    /* not 48px */
    --glowva-font-size-vertical-marquee: 2.6rem;
  }
}

/* Medium screens (>= 577px && < 992px) */
@media (min-width: 577px) and (max-width: 991px) {
  :root {
    --glowva-font-size-h1: 1.5rem;
    --glowva-font-size-h2: 1.3125rem;
    --glowva-font-size-h3: 1.125rem;
    --glowva-font-size-h4: 1rem;
    --glowva-font-size-h5: 0.9375rem;
    --glowva-font-size-h6: 0.85rem;
    --glowva-font-size-p: 0.75rem;
    --glowva-font-size-s: 0.66rem;
    --glowva-font-size-xs: 0.61rem;
    --glowva-font-size-intro-title: 3rem;
    --glowva-font-size-intro-subtitle: 1.875rem;
    --glowva-font-size-intro-p: 1.35rem;
    --glowva-font-size-intro-learn-more: var(--glowva-font-size-h5);
    --glowva-font-size-48px: 1.5rem;
    --glowva-font-size-vertical-marquee: 1.5rem;
  }
}

/* Small screens (<=576px) */
@media (max-width: 576px) {
  :root {
    --glowva-font-size-h1: 1.5rem;
    --glowva-font-size-h2: 1.25rem;
    --glowva-font-size-h3: 1rem;
    --glowva-font-size-h4: 1rem;
    --glowva-font-size-h5: 0.875rem;
    --glowva-font-size-h6: 0.77rem;
    --glowva-font-size-p: 0.875rem;
    --glowva-font-size-s: 0.6rem;
    --glowva-font-size-xs: 0.5rem;
    --glowva-font-size-intro-title: 1.7rem;
    --glowva-font-size-intro-subtitle: 1rem;
    --glowva-font-size-intro-p: 0.8rem;
    --glowva-font-size-intro-learn-more: 0.7rem;
    --glowva-font-size-48px: 1.25rem;
    --glowva-font-size-vertical-marquee: 0.9rem;
  }
}

/* end define variables */

/* font settings */
html {
  /* define the root font size */
  font-size: 16px !important;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border: none;
  line-height: 140% !important;
}

*,
body {
  font-family: 'Poppins', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-weight: 400;
  font-style: normal !important;
  letter-spacing: 0rem !important;
  line-height: 140% !important;

  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden
}

.glowva-text-h1 {
  font-size: var(--glowva-font-size-h1);
}

.glowva-text-h2 {
  font-size: var(--glowva-font-size-h2);
}

.glowva-text-h3 {
  font-size: var(--glowva-font-size-h3);
}

.glowva-text-h4 {
  font-size: var(--glowva-font-size-h4);
}

.glowva-text-h5 {
  font-size: var(--glowva-font-size-h5);
}

.glowva-text-h6 {
  font-size: var(--glowva-font-size-h6);
}

.glowva-text-p {
  font-size: var(--glowva-font-size-p);
}

.glowva-text-s {
  font-size: var(--glowva-font-size-s);
}

.glowva-text-xs {
  font-size: var(--glowva-font-size-xs);
}

.glowva-text-intro-title {
  font-size: var(--glowva-font-size-intro-title);
}

.glowva-text-intro-subtitle {
  font-size: var(--glowva-font-size-intro-subtitle);
}

.glowva-text-intro-p {
  font-size: var(--glowva-font-size-intro-p);
}

.glowva-text-intro-learn-more {
  font-size: var(--glowva-font-size-intro-learn-more);
}

.glowva-text-48px {
  font-size: var(--glowva-font-size-48px);
}

.glowva-text-color-primary {
  color: var(--glowva-primary);
}

.glowva-text-color-secondary-white {
  color: var(--glowva-secondary-white);
}

.glowva-text-color-tertiary-dark-green {
  color: var(--glowva-tertiary-dark-green);
}

.text-color-feature-gray {
  color: var(--glowva-feature-gray);
}

.text-color-light-gray {
  color: var(--glowva-light-gray);
}

.text-color-black {
  color: rgb(0, 0, 0) !important;
}

.glowva-text-super-bold {
  font-weight: 900;
}

.glowva-text-extra-bold {
  font-weight: 800;
}

.glowva-text-bold {
  font-weight: 700;
}

.glowva-text-semi-bold {
  font-weight: 600;
}

.glowva-text-medium {
  font-weight: 500;
}

/* end font settings */
/* -------------------------------------------------------------------- */
/* top nav bar */
@media (max-width: 991px) {
  .navbar-container {
    width: 40%;
  }
}

.nav-bar-logo {
  width: 128px;
}

.nav-top-item.active {
  color: var(--glowva-primary) !important;
}

.nav-top-item:active {
  color: var(--glowva-primary) !important;
}

@media (min-width: 992px) {
  .nav-top-item.active:hover {
    color: var(--glowva-primary) !important;
    animation: none !important;
  }

  .nav-top-item.active:not(:hover) {
    animation: none !important;
  }

  .nav-top-item:hover {
    animation: round-move-in 0.2s ease-in;
    z-index: 10;
  }

  .nav-top-item:not(:hover) {
    animation: round-move-opposite 0.2s ease-in;
    z-index: 10;
  }
}


@keyframes round-move-in {
  0% {
    transform: translateY(8px) rotateX(30deg);
    opacity: 0;
  }

  1% {
    transform: translateY(8px) rotateX(30deg);
    opacity: 0.5;
  }

  55% {
    transform: translateY(4px) rotateX(20deg);
    opacity: 1;
  }

  75% {
    transform: translateY(2px) rotateX(10deg);
    opacity: 1;
  }

  100% {
    transform: translateY(0) rotateX(0deg);
    opacity: 1;
  }
}

@keyframes round-move-opposite {
  0% {
    transform: translateY(-8px) rotateX(30deg);
    opacity: 0;
  }

  1% {
    transform: translateY(-8px) rotateX(30deg);
    opacity: 0.5;
  }

  55% {
    transform: translateY(-4px) rotateX(20deg);
    opacity: 1;
  }

  75% {
    transform: translateY(-2px) rotateX(10deg);
    opacity: 1;
  }

  100% {
    transform: translateY(0) rotateX(0deg);
    opacity: 1;
  }
}

/* end top nav bar */
/* -------------------------------------------------------------------- */
/* Introduction carousel */

.carousel-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
}

.intro-carousel-bg {
  width: 100%;
  max-width: 100%;
  height: 100vh;
  object-fit: cover;
}

.intro-carousel-text {
  position: absolute;
  top: 8%;
  left: 9%;
  z-index: 10;
  text-wrap: wrap;
  width: 70%;
  animation-name: intro-text-slide-in;
  animation-duration: 3s;
  animation-timing-function: linear;
}

@keyframes intro-text-slide-in {
  0% {
    transform: translateX(-200%);
    opacity: 0;
  }

  20% {
    transform: translateX(-120%);
    opacity: 1;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

.intro-thumbnail {
  position: absolute;
  bottom: 10%;
  right: 5%;
  z-index: 10;
  background-color: transparent;
}

.intro-thumbnail-img {
  width: 168px;
  object-fit: cover;
  border: 2px solid transparent;
  margin: 0px 0px 0px 10px;
}

.intro-thumbnail-img.active {
  border: 2px solid var(--glowva-secondary-white);
  /* Active border */
}

/* end Introduction carousel */
/* -------------------------------------------------------------------- */
/* Feature session */
.btn-feature-learn-more {
  background-color: transparent;
  border-style: solid;
  border-color: var(--glowva-primary);
  color: var(--glowva-primary);
  padding: 8px 16px;
  border-radius: 25px;

}

.img-feature {
  /* max-height: 80vh; */
  height: auto;
  max-width: 100%;
  object-fit: contain;
  z-index: -1;
  overflow: hidden;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.4);
}

.feature-id-border {
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: var(--glowva-feature-gray);
}

/* end Feature session */
/* -------------------------------------------------------------------- */
/* join us button and subscribe button */
.btn-join-us {
  background-color: var(--glowva-primary);
  border: none;
  color: var(--glowva-secondary-white);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: var(--glowva-font-size-h5);
  padding: 8px 16px;
  border-radius: 25px;
}

.btn-join-us {
  position: relative;
  overflow: hidden;
}

.btn-join-us .btn-text {
  display: inline-block;
  transition: transform 0.2s ease-in, opacity 0.2s ease-in;
}

.btn-join-us:hover .btn-text {
  animation: round-move-in 0.2s ease-in;
}

.btn-join-us:not(:hover) .btn-text {
  animation: round-move-opposite 0.2s ease-in;
}

.btn-join-us:active {
  /* change color when "clicking" */
  background-color: var(--glowva-tertiary-green);
}

/* end join us button and subscribe button */

/* subscription field */
.subscription-section-bg {
  background-color: #F1F1F1;
}

.subscription-box {
  padding: 16px 32px;
  border-radius: 48px;
  background-color: white;
  border: none;
  width: 50%;
}

@media (max-width: 576px) {
  .subscription-box {
    width: 85%;
  }
}

.subscription-input-field {
  border: none;
  width: 90%;
}

.subscription-input-field:focus {
  outline: none;
}

.animal-bg-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.animal-bg-container img {
  position: relative;
  /* opacity: 25%; */
  opacity: 1;
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: center;
}

@media (max-width: 576px) {
  .animal-bg-container img {
    width: 110%;
  }
}

.animal-bg-text {
  position: absolute;
  z-index: 10;
  top: 30%;
  left: 5%;
  width: 100%;
  font-size: var(--glowva-font-size-vertical-marquee);
}

/* -------------------------------------------------------------------- */
/* end subscription field */

/* bottom navigation */
.move-to-up-icon:hover {
  cursor: pointer;
}

/* end bottom navigation */

/* bottom copyright */
.brand-logo {
  height: 25px;
}

/* end end subscription field */
/* -------------------------------------------------------------------- */
/* coming soon and error 404 page */

.robot-img-coming-soon {
  width: 300px;
}

.btn-back-coming-soon {
  background-color: var(--glowva-secondary-white);
  border: solid var(--glowva-light-green);
  color: var(--glowva-light-green);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: var(--glowva-font-size-h5);
  padding: 8px 16px;
  border-radius: 25px;
  width: 192px
}

.btn-back-coming-soon {
  position: relative;
  overflow: hidden;
}

.btn-back-coming-soon .btn-text {
  display: inline-block;
  transition: transform 0.2s ease-in, opacity 0.2s ease-in;
}

.btn-back-coming-soon .btn-text {
  animation: round-move-in 0.2s ease-in;
}

.btn-back-coming-soon .btn-text {
  animation: round-move-opposite 0.2s ease-in;
}

.btn-back-coming-soon:active {
  /* change color when "clicking" */
  background-color: var(--glowva-tertiary-green);
}

/* end coming soon and error 404 page */
/* -------------------------------------------------------------------- */
/* Contact Page */
.contact-form-field {
  width: 60%;
  position: relative;
  /* left: 30%; */
}

@media (max-width: 576px) {
  .contact-form-field {
    width: 90%;
  }
}

/* end Contact Page */
/* -------------------------------------------------------------------- */